<template>
  <div class="riskControl">
    <el-row :gutter="20">
      <el-col :span="14"
        ><div class="getRiskControlInfo">
          <div class="item-l">
            <div class="box">
              <div class="txt">风控调用数量:</div>
              <div class="num">
                {{ riskData.totalRiskManagementCount || 0 }}
              </div>
            </div>
            <div class="box">
              <div class="txt">今日调用次数:</div>
              <div class="num">
                {{ riskData.totalRiskManagementCountToday || 0 }}
              </div>
            </div>
          </div>
          <div class="item-r">
            <div class="getInterfaceTimes-r">
              <div class="circle te">
                <div class="num">
                  <countTo
                    :startVal="0"
                    :endVal="
                      riskData.totalDataMaskRules
                        ? Number(riskData.totalDataMaskRules)
                        : 0
                    "
                    :duration="3000"
                  ></countTo>
                </div>
                <div class="txt">脱敏规则总数</div>
              </div>
            </div>
            <div class="getInterfaceTimes-r">
              <div class="circle te">
                <div class="num">
                  <countTo
                    :startVal="0"
                    :endVal="
                      riskData.totalEncryptionInfos
                        ? Number(riskData.totalEncryptionInfos)
                        : 0
                    "
                    :duration="3000"
                  ></countTo>
                </div>
                <div class="txt">加密算法总数</div>
              </div>
            </div>
            <div class="getInterfaceTimes-r">
              <div class="circle te">
                <div class="num">
                  <countTo
                    :startVal="0"
                    :endVal="
                      riskData.totalEncryptionRules
                        ? Number(riskData.totalEncryptionRules)
                        : 0
                    "
                    :duration="3000"
                  ></countTo>
                </div>
                <div class="txt">加密规则总数</div>
              </div>
            </div>
          </div>
        </div></el-col
      >
      <el-col :span="10"
        ><div class="getRiskControlInfo">
          <div class="item-l">
            <div class="box">
              <div class="txt">脱敏数据调用总数:</div>
              <div class="num">{{ riskData.totalDataMaskCount || 0 }}</div>
            </div>
            <div class="box">
              <div class="txt">查询手机号调用:</div>
              <div class="num">
                {{ riskData.totalPhoneCount || 0 }}
              </div>
            </div>
            <div class="box">
              <div class="txt">真实姓名调用:</div>
              <div class="num">
                {{ riskData.totalRealNameCount || 0 }}
              </div>
            </div>
            <div class="box">
              <div class="txt">用户房号调用:</div>
              <div class="num">
                {{ riskData.totalRoomNameCount || 0 }}
              </div>
            </div>
          </div>
          <div class="item-r">
            <div class="getInterfaceTimes-r">
              <div class="circle te">
                <div class="num">
                  {{ formatEstateStatus(riskData.riskManagementBank) }}
                </div>
                <div class="txt">风险控制等级</div>
              </div>
            </div>
          </div>
        </div></el-col
      >
    </el-row>
    <!-- 资源每日调用次数 -->
    <el-row class="resourceinvocation">
      <el-col class="item" :span="24"
        ><div class="interfaceDay">
          <!-- 标题 -->
          <div class="item-t">
            <div class="title">资源每日调用次数曲线图</div>
            <div class="time">
              <v-date-picker
                v-model="createTime"
                type="daterange"
                formatValue="yyyy-MM-dd"
                format="yyyy-MM-dd"
                startPlaceholder="开始时间"
                endPlaceholder="结束时间"
                @change="changeDate"
              />
            </div>
          </div>
          <!-- 图表-->
          <div class="item-b" ref="sourceEchart"></div></div
      ></el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { debounce } from "@/utils/utils";
import { getRiskControlInfo, riskInfoStsListByDay } from "./api.js";
import { riskEstateStatusMap } from "./map.js";
import countTo from "vue-count-to";
import moment from "moment";
export default {
  name: "riskControl",
  data() {
    return {
      riskData: {}, // 页面数据
      createTime: [
        moment(new Date()).subtract(15, "days").format("YYYY-MM-DD"),
        moment(new Date()).format("YYYY-MM-DD"),
      ],
      ehartList: [], // 每日调用次数图表数据
      //
      staticList: [
        {
          id: "",
          clientIdxId: "",
          openId: "6",
          stsDay: "",
          stsDayCount: "",
          createTime: "",
          tag: "",
          account: "",
          openUrl: "",
          openUrlName: "",
          totalStsCount: "0",
        },
        {
          id: "",
          clientIdxId: "",
          openId: "22",
          stsDay: "",
          stsDayCount: "",
          createTime: "",
          tag: "",
          account: "",
          openUrl: "",
          openUrlName: "",
          totalStsCount: "0",
        },
        {
          id: "",
          clientIdxId: "",
          openId: "23",
          stsDay: "",
          stsDayCount: "",
          createTime: "",
          tag: "",
          account: "",
          openUrl: "",
          openUrlName: "",
          totalStsCount: "0",
        },
        {
          id: "",
          clientIdxId: "",
          openId: "24",
          stsDay: "",
          stsDayCount: "",
          createTime: "",
          tag: "",
          account: "",
          openUrl: "",
          openUrlName: "",
          totalStsCount: "0",
        },
      ],
      dateList: [], // 日期数据
      desensitizationList: [], // 脱敏数据
      phoneList: [], //获取手机号数据
      nameList: [], // 真实姓名数据
      houseList: [], // 真实房号数据
      riskEchart: "", // 图表名
    };
  },
  components: {
    countTo,
  },
  created() {
    this.getRiskControlData();
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler, true);
    this.getRiskInfoStsListByDay(this.createTime[0], this.createTime[1]);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    //  图表
    getRiskEchart(date, res6, res22, res23, res24) {
      this.riskEchart = echarts.init(this.$refs.sourceEchart);
      const option = {
        legend: {
          top: "8%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        dataset: {
          source: [
            ["product", ...date],
            ["脱敏数据接口", ...res6],
            ["获取手机号", ...res22],
            ["获取真实姓名", ...res23],
            ["获取真实房号", ...res24],
          ],
        },
        xAxis: {
          type: "category",
          alignWithLabel: true,
          axisLabel: {
            showMaxLabel: true,
            interval: 1,
          },
        },
        yAxis: { gridIndex: 0 },
        grid: [
          { left: "5%", top: "25%", width: "55%" },
          { right: "5%", top: "7%" },
        ],
        series: [
          {
            type: "line",
            itemStyle: { normal: { label: { show: true } } },
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            type: "line",
            itemStyle: { normal: { label: { show: true } } },
            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            type: "line",
            itemStyle: { normal: { label: { show: true } } },

            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            type: "line",
            itemStyle: { normal: { label: { show: true } } },

            seriesLayoutBy: "row",
            emphasis: { focus: "series" },
          },
          {
            type: "pie",
            id: "pie",
            radius: [0, 140],
            roseType: "area",
            center: ["80%", "60%"],
            itemStyle: {
              borderRadius: 5,
            },
            emphasis: {
              focus: "self",
            },
            label: {
              formatter: (params) => {
                if (params.name !== "") {
                  return (
                    `${params.name}${params.value[params.encode.value[0]]}` +
                    "\xa0" +
                    `(${params.percent === undefined ? 0 : params.percent}%)`
                  );
                }
              },
            },
            encode: {
              itemName: "product",
              value: date[0],
              tooltip: date[0],
            },
          },
        ],
      };
      let that = this;
      this.riskEchart.on("updateAxisPointer", function (event) {
        const xAxisInfo = event.axesInfo[0];
        if (xAxisInfo) {
          const dimension = xAxisInfo.value + 1;
          that.riskEchart.setOption({
            series: {
              id: "pie",
              label: {
                formatter: (params) => {
                  if (params.name !== "") {
                    return (
                      `${params.name}${params.value[[dimension]]}` +
                      "\xa0" +
                      `(${params.percent === undefined ? 0 : params.percent}%)`
                    );
                  }
                },
              },
              encode: {
                value: dimension,
                tooltip: dimension,
              },
            },
          });
        }
      });
      this.riskEchart.setOption(option);
    },
    // 获取资源每日调用次数曲线图数据
    getRiskInfoStsListByDay(startTime, endTime) {
      const params = {
        startTime,
        endTime,
      };
      this.$axios.get(`${riskInfoStsListByDay}`, { params }).then((res) => {
        if (res.code === 200) {
          this.ehartList = this.formatEhartList(res.data);
          this.ehartList.forEach((item) => {
            this.dateList.push(item.stsDay);
            item.stsList.forEach((v) => {
              if (v.openId == "6") {
                this.desensitizationList.push(v.totalStsCount);
              }
              if (v.openId == "22") {
                this.phoneList.push(v.totalStsCount);
              }
              if (v.openId == "23") {
                this.nameList.push(v.totalStsCount);
              }
              if (v.openId == "24") {
                this.houseList.push(v.totalStsCount);
              }
            });
          });
          this.getRiskEchart(
            this.dateList,
            this.desensitizationList,
            this.phoneList,
            this.nameList,
            this.houseList
          );
        }
      });
    },
    // 获取页面 数据
    getRiskControlData() {
      this.$axios.get(`${getRiskControlInfo}`).then((res) => {
        if (res.code === 200) {
          this.riskData = res.data;
        }
      });
    },
    // 选择时间调用接口
    changeDate() {
      this.ehartList = [];
      (this.dateList = []),
        (this.desensitizationList = []),
        (this.phoneList = []),
        (this.nameList = []),
        (this.houseList = []);

      this.getRiskInfoStsListByDay(this.createTime[0], this.createTime[1]);
    },
    // 处理返回的数据
    formatEhartList(arr) {
      arr.forEach((item, index) => {
        if (item.stsList.length === 0) {
          item.stsList = this.staticList;
        } else {
          const openId = item.stsList.map((item) => item.openId);
          const isOpenid6 = openId.includes("6");
          const isOpenid22 = openId.includes("22");
          const isOpenid23 = openId.includes("23");
          const isOpenid24 = openId.includes("24");
          if (!isOpenid6) {
            item.stsList.push({
              id: "",
              clientIdxId: "",
              openId: "6",
              stsDay: "",
              stsDayCount: "",
              createTime: "",
              tag: "",
              account: "",
              openUrl: "",
              openUrlName: "",
              totalStsCount: "0",
            });
          }
          if (!isOpenid22) {
            item.stsList.push({
              id: "",
              clientIdxId: "",
              openId: "22",
              stsDay: "",
              stsDayCount: "",
              createTime: "",
              tag: "",
              account: "",
              openUrl: "",
              openUrlName: "",
              totalStsCount: "0",
            });
          }
          if (!isOpenid23) {
            item.stsList.push({
              id: "",
              clientIdxId: "",
              openId: "23",
              stsDay: "",
              stsDayCount: "",
              createTime: "",
              tag: "",
              account: "",
              openUrl: "",
              openUrlName: "",
              totalStsCount: "0",
            });
          }
          if (!isOpenid24) {
            item.stsList.push({
              id: "",
              clientIdxId: "",
              openId: "24",
              stsDay: "",
              stsDayCount: "",
              createTime: "",
              tag: "",
              account: "",
              openUrl: "",
              openUrlName: "",
              totalStsCount: "0",
            });
          }
        }
      });
      return arr;
    },
    // 处理风险等级数据
    formatEstateStatus(args) {
      return riskEstateStatusMap[args];
    },
    //echart图表自适应
    resizeHandler() {
      return debounce(() => {
        this.riskEchart.resize();
      }, 100)();
    },
  },
};
</script>
<style lang="less" scoped>
.riskControl {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  padding: 60px 20px 20px 20px;
  overflow-x: hidden;
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: #f5f5f5;
  }

  /*定义滑块 内阴影+圆角*/
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
  }
  overflow-y: auto;
  .title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  .getRiskControlInfo {
    width: 100%;
    height: 280px;
    background: #ffff;
    box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 40px 40px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    .item-l {
      display: flex;
      flex-direction: column;
      .box {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .num {
          font-size: 28px;
          font-weight: 700;
        }
        .txt {
          font-size: 20px;
          color: #666;
          margin-right: 10px;
          word-break: keep-all;
          white-space: nowrap;
        }
      }
    }
    .item-r {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .getInterfaceTimes-r {
        width: 150px;
        height: 150px;
        border: 8px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .circle {
          .num {
            font-size: 80px;
            font-weight: 700;
            color: rgba(54, 184, 119, 1);
          }
          .txt {
            font-size: 16px;
            color: #666;
          }
        }
        .te {
          .num {
            color: rgba(131, 128, 255, 1);
          }
        }
        .num,
        .txt {
          text-align: center;
        }
      }
    }
  }
  .resourceinvocation {
    flex: 1;
    margin-top: 20px;
    .item {
      height: 100%;
      .interfaceDay {
        display: flex;
        flex-direction: column;

        height: 100%;
        background: #ffff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        padding: 20px;
        box-sizing: border-box;
        .item-t {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding: 0 40px;
          .time {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .item-b {
          height: 600px;
          display: flex;
        }
      }
    }
  }
}
</style>
