import { mapHelper } from "@/utils/common.js";
//风险等级状态
const riskEstateStatus = [
  {
    value: 1,
    label: "A",
  },
  {
    value: 2,
    label: "B",
  },
  {
    value: 3,
    label: "C",
  },
  {
    value: 4,
    label: "D",
  },
];
const { map: riskEstateStatusMap, setOps: riskEstateStatusOpt } = mapHelper.setMap(riskEstateStatus);
export {
  riskEstateStatusMap,
  riskEstateStatusOpt,
};
